import { ArrowGoForward } from '@styled-icons/remix-line';
import { ArrowGoForward as ArrowGoForwardFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function RedoIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ArrowGoForward />
    </Icon>
  );
}

export function RedoFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ArrowGoForwardFill />
    </Icon>
  );
}
