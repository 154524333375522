import { Subtract } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function RemoveIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Subtract />
    </Icon>
  );
}
