import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';

export const AddAction = styled.button<{ $isOpen: boolean }>`
  padding: 16px 16px;
  width: calc(50% - 4px);
  border: 1px solid ${Colors.GreyLightest};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: ${Colors.White};

  &:hover {
    opacity: 0.8;
    background-color: ${Colors.ContrastLightest};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background-color: ${Colors.ContrastUltraLightest};
    `}
`;
