import { DesignTemplateProps } from './DesignTemplate.types';
import {
  DesignPalettes,
  Container,
  DesignPaletteButton,
  SelectionLabel,
  SelectionIndicator,
  ImageContainer,
  LeftIconContainer,
  RightIconContainer,
} from './DesignTemplate.styles';
import { DesignTemplateName } from '../../../generated/types';
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from '../Icons';
import { useState } from 'react';
import { IconButton } from '../Icons/Icon';

const MAX_TEMPLATES = 3;
const TEMPLATES = [
  DesignTemplateName.Aries,
  DesignTemplateName.Taurus,
  DesignTemplateName.Cancer,
  DesignTemplateName.Virgo,
  DesignTemplateName.Libra,
  DesignTemplateName.Scorpio,
  // DesignTemplateName.Gemini,
];

export const DesignTemplate = ({
  templateName,
  documentType = 'resume',
  onActionClick,
}: React.PropsWithChildren<DesignTemplateProps>) => {
  const [inview, setInview] = useState<number>(() => TEMPLATES.findIndex((template) => template === templateName));
  const min = inview % TEMPLATES.length;
  let max = (inview + MAX_TEMPLATES) % TEMPLATES.length;
  max = max < min ? TEMPLATES.length : max;
  const templatesInView = TEMPLATES.slice(min, max);
  if (templatesInView.length < MAX_TEMPLATES) {
    const diff = MAX_TEMPLATES - templatesInView.length;
    const remaining = TEMPLATES.slice(0, diff);
    templatesInView.push(...remaining);
  }
  return (
    <Container>
      <LeftIconContainer>
        <IconButton>
          <ChevronLeftIcon size={3} onClick={() => setInview(inview - 1)} />
        </IconButton>
      </LeftIconContainer>
      <RightIconContainer>
        <IconButton>
          <ChevronRightIcon size={3} onClick={() => setInview(inview + 1)} />
        </IconButton>
      </RightIconContainer>
      <DesignPalettes>
        {templatesInView.map((template) => (
          <DesignPalette
            key={template}
            image={`/images/${documentType}/${template}.png`}
            selected={templateName === template}
            onClick={() => onActionClick(template)}
          >
            {template}
          </DesignPalette>
        ))}
      </DesignPalettes>
    </Container>
  );
};

const DesignPalette = (props: { image: string; selected: boolean; children: React.ReactNode; onClick: () => void }) => {
  const { image, selected, children, onClick } = props;
  const [loading, setLoading] = useState(true);

  return (
    <DesignPaletteButton $selected={selected} onClick={onClick}>
      <ImageContainer $loading={loading}>
        <img src={image} alt="theme image" onLoad={() => setLoading(false)} />
      </ImageContainer>
      <SelectionLabel>{children}</SelectionLabel>
      <SelectionIndicator $selected={selected}>
        <CheckIcon />
      </SelectionIndicator>
    </DesignPaletteButton>
  );
};
