import { DesignFontFamilyProps } from './DesignFontFamily.types';
import { FontPalette, FontPalettes, Container, Label } from './DesignFontFamily.styles';
import { DesignFontFamily as DesignFontFamilyEnum } from '../../../generated/types';

export const DesignFontFamily = ({ fontFamily, onActionClick }: React.PropsWithChildren<DesignFontFamilyProps>) => {
  return (
    <Container>
      <FontPalettes>
        <FontPalette
          $selected={fontFamily === DesignFontFamilyEnum.Palatino}
          onClick={() => onActionClick(DesignFontFamilyEnum.Palatino)}
        >
          <Label
            style={{
              fontFamily: '"Palatino", "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;',
              fontSize: '2rem',
            }}
          >
            Palatino
          </Label>
        </FontPalette>
        <FontPalette
          $selected={fontFamily === DesignFontFamilyEnum.Helvetica}
          onClick={() => onActionClick(DesignFontFamilyEnum.Helvetica)}
        >
          <Label
            style={{
              fontFamily: 'Arial", "Helvetica", sans-serif;',
              fontSize: '2rem',
            }}
          >
            Helvetica
          </Label>
        </FontPalette>
        <FontPalette
          $selected={fontFamily === DesignFontFamilyEnum.Courier}
          onClick={() => onActionClick(DesignFontFamilyEnum.Courier)}
        >
          <Label
            style={{
              fontFamily: '"Courier New", Courier, monospace;',
              fontSize: '2rem',
            }}
          >
            Courier
          </Label>
        </FontPalette>
      </FontPalettes>
    </Container>
  );
};
