import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationFixTypoArgs } from '../../generated/types';

export const FIX_TYPO = gql`
  mutation FixTypo($data: FixTypoData!) {
    fixTypo(data: $data)
  }
`;

export const useFixTypo = (options?: MutationHookOptions<Pick<Mutation, 'fixTypo'>, MutationFixTypoArgs>) =>
  useMutation<Pick<Mutation, 'fixTypo'>, MutationFixTypoArgs>(FIX_TYPO, {
    ...options,
  });
