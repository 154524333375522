import { ResumeDesignOptionsFormProps } from './ResumeDesignOptionsForm.types';
import { useState } from 'react';

import { AddIcon, RemoveIcon } from '../../atoms/Icons';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { AddActionLabel, Container, FullItem, GroupItems, HalfItem } from '../ResumeCommonForm';
import { AddAction } from './ResumeDesignOptionsForm.styles';
import { ErrorBoundary } from '../ErrorBoundary';
import { CheckboxInput } from '../../atoms/Form/CheckboxInput';
import { Copy } from '../../atoms/Typography';
import { SelectInput } from '../../atoms/Form/SelectInput/SelectInput';

export const ResumeDesignOptionsForm = ({ dispatch, design }: ResumeDesignOptionsFormProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { hasLastModifiedDate = false, hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;
  return (
    <ErrorBoundary message="Molecule\ResumeDesignOptionsForm">
      <Container>
        <GroupItems>
          <FullItem>
            <AddAction
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              $isOpen={true}
            >
              {isOpen ? (
                <>
                  <RemoveIcon size={2} />
                  <AddActionLabel>Hide additional details</AddActionLabel>
                </>
              ) : (
                <>
                  <AddIcon size={2} />
                  <AddActionLabel>Show additional details</AddActionLabel>
                </>
              )}
            </AddAction>
            <Spacer y={16} />
          </FullItem>
          {isOpen && (
            <HalfItem>
              <SelectInput
                onChange={(event) => {
                  dispatch({
                    type: 'CHANGE_DESIGN_VALUE',
                    payload: { key: 'paragraphTextAlignment', value: event.target.value },
                  });
                }}
                name="paragraphTextAlignment"
                noMargin={true}
                label="Paragraph Alignment"
                value={paragraphTextAlignment}
              >
                <option value="left">Left</option>
                <option value="justify">Justify</option>
                <option value="right">Right</option>
              </SelectInput>
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <CheckboxInput
                isChecked={hasParagraphIndendation}
                name="hasParagraphIndendation"
                onClick={(event) => {
                  dispatch({
                    type: 'CHANGE_DESIGN_VALUE',
                    payload: { key: 'hasParagraphIndendation', value: event.target.checked },
                  });
                }}
                label={
                  <>
                    <Copy marginBottom={0} styleLevel={2}>
                      Indentation Paragraph
                    </Copy>
                    <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                      Set the indentation of the first line of a paragraph.
                    </Copy>
                  </>
                }
                noMargin
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <CheckboxInput
                isChecked={hasLastModifiedDate}
                name="hasLastModifiedDate"
                onClick={(event) => {
                  dispatch({
                    type: 'CHANGE_DESIGN_VALUE',
                    payload: { key: 'hasLastModifiedDate', value: event.target.checked },
                  });
                }}
                label={
                  <>
                    <Copy marginBottom={0} styleLevel={2}>
                      Display Last Modified Date
                    </Copy>
                    <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                      Display the last modified date on your resume footer.
                    </Copy>
                  </>
                }
                noMargin
              />
            </HalfItem>
          )}
        </GroupItems>
      </Container>
    </ErrorBoundary>
  );
};
