import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const Container = styled.div``;

export const FontPalettes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Primary};
  padding: 8px;
`;

export const FontPalette = styled.button<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 8px;
  height: 68px;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;

  border: 1px solid ${Colors.Contrast};
  ${({ $selected }) =>
    $selected &&
    `
    border: 4px solid ${Colors.PrimaryDarkest};
    `}
`;

export const ColorPaletteSlice = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  scale: 1;
  transition: scale 0.5s cubic-bezier(0.33, -0.14, 0, 1.76);
`;

export const Label = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${Colors.Primary};
`;
