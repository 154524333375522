import { DesignFontSizeProps } from './DesignFontSize.types';
import { FontPalette, FontPalettes, Container, Label } from './DesignFontSize.styles';
import { LineHeightDenseIcon, LineHeightLooseIcon, LineHeightNormalIcon } from '../Icons';
import { DesignDensity } from '../../../generated/types';

export const DesignFontSize = ({ density, onActionClick }: React.PropsWithChildren<DesignFontSizeProps>) => {
  return (
    <Container>
      <FontPalettes>
        <FontPalette $selected={density === DesignDensity.Dense} onClick={() => onActionClick(DesignDensity.Dense)}>
          <LineHeightDenseIcon size={1.5} />
          <Label style={{ fontSize: '.9rem' }}>Dense</Label>
        </FontPalette>
        <FontPalette $selected={density === DesignDensity.Normal} onClick={() => onActionClick(DesignDensity.Normal)}>
          <LineHeightNormalIcon size={1.5} />
          <Label style={{ fontSize: '1rem' }}>Normal</Label>
        </FontPalette>
        <FontPalette $selected={density === DesignDensity.Loose} onClick={() => onActionClick(DesignDensity.Loose)}>
          <LineHeightLooseIcon size={1.5} />
          <Label style={{ fontSize: '1.1rem' }}>Loose</Label>
        </FontPalette>
      </FontPalettes>
    </Container>
  );
};
