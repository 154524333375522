import { CheckmarkCircle2 } from '@styled-icons/evaicons-solid';
import { Icon, IconProps } from './Icon';

export function CheckFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <CheckmarkCircle2 />
    </Icon>
  );
}
