import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const Container = styled.div``;

export const ColorPalettes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Primary};
  padding: 8px;
`;

export const ColorPalette = ({
  selected,
  primary,
  secondary,
  contract,
  background,
  sidebar,
  text,
  onClick,
  name,
}: any) => {
  return (
    <ColorPaletteContainer $selected={selected} onClick={onClick}>
      <ColorPaletteSlice $backgroundcolor={primary} $color={text}></ColorPaletteSlice>
      <ColorPaletteSlice $backgroundcolor={secondary} $color={text}></ColorPaletteSlice>
      <ColorPaletteSlice $backgroundcolor={contract} $color={text}></ColorPaletteSlice>
      <ColorPaletteSlice $backgroundcolor={sidebar} $color={text}></ColorPaletteSlice>
      <ColorPaletteSlice $backgroundcolor={background} $color={text} $selected={selected}>
        {name}
      </ColorPaletteSlice>
    </ColorPaletteContainer>
  );
};

export const ColorPaletteSlice = styled.span<{ $selected?: boolean; $color: string; $backgroundcolor: string }>`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $backgroundcolor }) => $backgroundcolor && `background-color: ${$backgroundcolor};`}
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $selected }) => $selected && `flex: 4;`}
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
  &:first-child {
    border-radius: 4px 4px 0 0;
  }
`;

export const ColorPaletteContainer = styled.button<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 120px;
  width: 120px;
  border: 1px solid ${Colors.Contrast};
  border-radius: 8px;
  cursor: pointer;
  ${({ $selected }) =>
    $selected &&
    `
    border: 4px solid ${Colors.PrimaryDarkest};
    `}
`;
