import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div``;

export const PaperTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-size: 2rem;
`;

export const ItemContainer = styled.div<{ $isDragging: boolean }>`
  background-color: ${Colors.White};
  flex: 1;
  border-radius: 8px;
  ${(props) =>
    props.$isDragging &&
    css`
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    `}
`;

export const GroupItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 16px 0px 16px;
  border: 1px solid ${Colors.GreyLightest};
  border-radius: 8px;
  position: relative;
`;

export const FullItem = styled.div`
  width: 100%;
`;

export const HalfItem = styled.div`
  width: calc(50% - 4px);
  z-index: 1;
`;

export const QuarterItem = styled.div`
  width: calc(25% - 6px);
`;

export const AddAction = styled.button`
  padding: 16px 16px;
  width: calc(50% - 4px);
  border: 1px solid ${Colors.GreyLightest};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:hover {
    opacity: 0.8;
  }
  margin-left: 40px;
`;

export const AddActionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const DraggableContainer = styled.section<{ $isDragging: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin-bottom: 16px;
  transition: background-color 0.3s ease-in-out;
  /* ${(props) =>
    props.$isDragging &&
    css`
      box-shadow: 0 0 0 2px ${Colors.ContrastLightest};
    `} */
`;

export const SlateEditorWrapper = styled.div`
  border: 1px solid ${Colors.GreyLightest};
  border-radius: 8px;
  margin-bottom: 16px;
`;
