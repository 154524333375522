import { useState } from 'react';
import { Editor, Path, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { ElementType, SlateEditorProps } from './SlateEditor.types';

import { Spacer } from '../../atoms/Spacer/Spacer';
import { PrimaryButton } from '../../atoms/Button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  EraserIcon,
  SparklingIcon,
  SpinnerIcon,
} from '../../atoms/Icons';
import { IconButton } from '../../atoms/Icons/Icon';
import { H4 } from '../../atoms/Typography';
import { BulletList } from '../../atoms/BulletList';
import { serializeSlateNodeToHtml } from './helper';

export const Container = styled.div<SlateEditorProps>`
  div[role='textbox'] {
    padding: 10px;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid ${Colors.Primary};
      outline: none !important;
    }
  }
`;

export const ElementTemplate = (props: ElementType) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'help':
      return <HelpElement {...props} />;
    case 'loading':
      return <LoadingElement {...props} />;
    case 'suggestion':
      return <SuggestionsElement {...props} />;
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'paragraph':
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const LeafTemplate = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.highlight) {
    children = <Highlight>{children}</Highlight>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

// children is really important. Do not delete https://github.com/ianstormtaylor/slate/issues/3930 */}
const SuggestionsElement = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const [currentProposedIndex, setCurrentProposedIndex] = useState<number>(0);
  const { original, suggestions, start, end } = element;

  let html = '';
  try {
    html = serializeSlateNodeToHtml(original);
  } catch (error) {
    console.error('error', error);
  }

  let proposedValueHtml = '';
  try {
    proposedValueHtml = serializeSlateNodeToHtml(suggestions[currentProposedIndex]);
  } catch (error) {
    console.error('error', error);
  }

  const increase = () => {
    if (currentProposedIndex + 1 < suggestions.length) {
      setCurrentProposedIndex(currentProposedIndex + 1);
    }
  };

  const decrease = () => {
    if (currentProposedIndex - 1 >= 0) {
      setCurrentProposedIndex(currentProposedIndex - 1);
    }
  };

  return (
    <Suggestions {...attributes} contentEditable={false}>
      <SuggestionOriginalContent dangerouslySetInnerHTML={{ __html: html }}></SuggestionOriginalContent>
      <Suggestion>
        <ProposedValueContainer>
          <div dangerouslySetInnerHTML={{ __html: proposedValueHtml }}></div>
          <Spacer y={8} />
          <ProposedValueActions>
            <PrimaryButton
              size="tiny"
              inline={true}
              onClick={() => {
                try {
                  const nodesCount = editor?.children.length;
                  const updatedNodes = [...editor?.children];
                  const suggestionNodes = [...suggestions[currentProposedIndex]];
                  updatedNodes.splice(start, 1, ...suggestionNodes);

                  Editor.withoutNormalizing(editor, () => {
                    // Insert the new nodes at the end of the document. This is important to
                    // duplicate key in react element.
                    Transforms.insertNodes(editor, updatedNodes, { at: [editor.children.length] });
                    // Remove all old nodes from the start to the end of the document.
                    for (let i = 0; i < nodesCount; i++) {
                      Transforms.removeNodes(editor, { at: [0] });
                    }
                  });
                } catch (error) {
                  console.error('error', error);
                }
              }}
            >
              Use
            </PrimaryButton>
            <ProposedValueNavigation>
              {currentProposedIndex === 0 ? <Spacer x={16} y={4} /> : <ChevronLeftIcon onClick={decrease} size={1.3} />}
              {currentProposedIndex + 1} / {suggestions.length}
              {currentProposedIndex + 1 === suggestions.length ? (
                <Spacer x={16} y={4} />
              ) : (
                <ChevronRightIcon onClick={increase} size={1.3} />
              )}
            </ProposedValueNavigation>
            <IconButton
              onClick={() => {
                try {
                  const reversed = [...original].reverse();
                  for (const item of reversed) {
                    Transforms.insertNodes(editor, item, { at: [start] });
                  }
                  Transforms.removeNodes(editor, {
                    at: [end + 1],
                  });
                } catch (error) {
                  console.error('error', error);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </ProposedValueActions>
        </ProposedValueContainer>
      </Suggestion>
      {/* children is really important. Do not delete https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
    </Suggestions>
  );
};

// children is really important. Do not delete https://github.com/ianstormtaylor/slate/issues/3930 */}
const LoadingElement = ({ element, children }: any) => {
  let html = '';
  try {
    const { original } = element;
    html = serializeSlateNodeToHtml(original);
  } catch (error) {
    console.error('error', error);
  }

  return (
    <Loading
      contentEditable={false}
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <LoadingContent dangerouslySetInnerHTML={{ __html: html }}></LoadingContent>
      <LoadingSpinner>
        <SpinnerIcon />
      </LoadingSpinner>
      {/* children is really important. Do not delete https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
    </Loading>
  );
};

const HelpElement = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  return (
    <div {...attributes}>
      <Help contentEditable={false}>
        <HelpAction>
          <IconButton
            onClick={() => {
              try {
                const path = ReactEditor.findPath(editor, element);
                Transforms.removeNodes(editor, { at: path });
              } catch (error) {
                console.error('error', error);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </HelpAction>
        <H4>How to use</H4>
        <LineWrapper>
          <EraserIcon />
          Fix spelling & grammar
        </LineWrapper>
        <BulletList $bulletColor={Colors.PrimaryDarkest} $hasBullets={true}>
          <li>Select the text section you want to spell-check.</li>
          <li>
            Look for the eraser icon - <EraserIcon size={1.2} />- in the toolbar.
          </li>
          <li>The AI Assistant will initiate a spell check, correcting any errors in the highlighted section.</li>
          <li>If you've made a change and wish to revert it, click the undo button.</li>
        </BulletList>

        <Spacer y={32} />

        <LineWrapper>
          <SparklingIcon />
          Improve writing
        </LineWrapper>
        <BulletList $bulletColor={Colors.PrimaryDarkest} $hasBullets={true}>
          <li>Highlight the text section you want the AI Assistant to improve.</li>
          <li>
            Look for the sparkling icon - <SparklingIcon size={1.1} />- in the toolbar.
          </li>
          <li>The AI Assistant will analyze the content and provide suggestions for enhancement.</li>
          <li>If you've made a change and wish to revert it, click the undo button.</li>
        </BulletList>

        {children}
      </Help>
    </div>
  );
};

const Loading = styled.code`
  position: relative;
  display: block;
`;

const LoadingContent = styled.code`
  color: ${Colors.GreyTransparent};
  p {
    min-height: 1rem;
  }
`;

const LoadingSpinner = styled.code`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const Suggestions = styled.code`
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
`;

const Suggestion = styled.code`
  margin-top: 8px;
  background-color: ${Colors.ContrastTransparent};
`;

const SuggestionOriginalContent = styled.code`
  color: ${Colors.GreyDarkest};
  display: block;
  p {
    min-height: 1rem;
  }
  border: 1px dotted ${Colors.GreyTransparent};
`;

const ProposedValueContainer = styled.code`
  display: block;
  flex: 1;
  flex-direction: column;
  padding: 6px;
  background-color: ${Colors.ContrastLightest};
`;

const ProposedValueActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${Colors.Contrast};
  padding: 4px 0 0px 0;
`;

const ProposedValueNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

const Highlight = styled.span`
  background-color: ${Colors.SecondaryLight};
`;

const Help = styled.div`
  padding: 8px 8px;
  border: 1px solid ${Colors.Contrast};
  background-color: ${Colors.ContrastTransparent};
  position: relative;
`;

const HelpAction = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: ${Colors.PrimaryDarkest};
  gap: 8px;
`;

export const Button = styled.button<{
  $active?: boolean;
}>`
  cursor: pointer;
  font-size: 1.6rem;
  svg {
    fill: ${(props) => (props.$active ? Colors.PrimaryDarkest : Colors.GreyLightest)};
  }
`;

export const ButtonInfo = styled.div`
  position: absolute;
  font-size: 0.6rem;
`;

export const Toolbar = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding: 16px 0;
`;

export const ToolbarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0px 8px;
`;

export const ExtraHighlight = ({ isLoading, children }: any) => {
  return (
    <ExtraHighlightContainer>
      <ExtraHighlightTop>
        <ExtraHighlightTopIcon>
          {isLoading ? <SpinnerIcon /> : <SparklingIcon color={Colors.Contrast} size={2} />}
        </ExtraHighlightTopIcon>
      </ExtraHighlightTop>
      {isLoading ? <ExtraHighlightContent>{children}</ExtraHighlightContent> : children}
    </ExtraHighlightContainer>
  );
};
const ExtraHighlightContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ExtraHighlightTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px dashed ${Colors.Contrast};
    display: block;
    height: 3px;
    width: calc(50% - 20px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 1px dashed ${Colors.Contrast};
    display: block;
    height: 3px;
    width: calc(50% - 20px);
  }
`;

const ExtraHighlightTopIcon = styled.div`
  transform: translateY(-22px);
`;

const ExtraHighlightContainer = styled.section`
  margin-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background-color: ${Colors.ContrastLightest};
  // gradient
  background: linear-gradient(
    180deg,
    ${Colors.ContrastLightest} 0%,
    ${Colors.ContrastUltraLightest} 30%,
    ${Colors.White} 90%,
    ${Colors.White} 100%
  );
  /* border-top: 1px dashed ${Colors.Contrast}; */
  padding: 8px;
  border-radius: 0 0 8px 8px;
`;

export const ExtraHighlightButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
`;
