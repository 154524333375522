import { DesignColorPaletteProps } from './DesignColorPalette.types';
import { ColorPalette, ColorPalettes, Container } from './DesignColorPalette.styles';
import { DesignColorPalette as DesignColorPaletteEnum } from '../../../generated/types';
import { getColorPalette } from '../../../helper/colorPalette';

export const DesignColorPalette = ({
  colorPalette,
  onActionClick,
}: React.PropsWithChildren<DesignColorPaletteProps>) => {
  const defaultColor = getColorPalette(DesignColorPaletteEnum.Default);
  const standard1Color = getColorPalette(DesignColorPaletteEnum.Standard1);
  const standard2Color = getColorPalette(DesignColorPaletteEnum.Standard2);
  const dark1Color = getColorPalette(DesignColorPaletteEnum.Dark1);
  const dark2Color = getColorPalette(DesignColorPaletteEnum.Dark2);
  const light1Color = getColorPalette(DesignColorPaletteEnum.Light1);
  const light2Color = getColorPalette(DesignColorPaletteEnum.Light2);
  const background1Color = getColorPalette(DesignColorPaletteEnum.Background1);
  const background2Color = getColorPalette(DesignColorPaletteEnum.Background2);

  return (
    <Container>
      <ColorPalettes>
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Default}
          primary={defaultColor.primaryColor}
          secondary={defaultColor.secondaryColor}
          contract={defaultColor.contrastColor}
          background={defaultColor.backgroundColor}
          text={defaultColor.textColor}
          header={defaultColor.headerColor}
          sidebar={defaultColor.sidebarBackgroundColor}
          sidebarText={defaultColor.sidebarTextColor}
          sidebarHeader={defaultColor.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Default)}
          name={'Goya'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Standard1}
          primary={standard1Color.primaryColor}
          secondary={standard1Color.secondaryColor}
          contract={standard1Color.contrastColor}
          background={standard1Color.backgroundColor}
          text={standard1Color.textColor}
          header={standard1Color.headerColor}
          sidebar={standard1Color.sidebarBackgroundColor}
          sidebarText={standard1Color.sidebarTextColor}
          sidebarHeader={standard1Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Standard1)}
          name={'Matisse'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Standard2}
          primary={standard2Color.primaryColor}
          secondary={standard2Color.secondaryColor}
          contract={standard2Color.contrastColor}
          background={standard2Color.backgroundColor}
          text={standard2Color.textColor}
          header={standard2Color.headerColor}
          sidebar={standard2Color.sidebarBackgroundColor}
          sidebarText={standard2Color.sidebarTextColor}
          sidebarHeader={standard2Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Standard2)}
          name={'Picasso'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Light1}
          primary={light1Color.primaryColor}
          secondary={light1Color.secondaryColor}
          contract={light1Color.contrastColor}
          background={light1Color.backgroundColor}
          text={light1Color.textColor}
          header={light1Color.headerColor}
          sidebar={light1Color.sidebarBackgroundColor}
          sidebarText={light1Color.sidebarTextColor}
          sidebarHeader={light1Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Light1)}
          name={'Cézanne'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Light2}
          primary={light2Color.primaryColor}
          secondary={light2Color.secondaryColor}
          contract={light2Color.contrastColor}
          background={light2Color.backgroundColor}
          text={light2Color.textColor}
          header={light2Color.headerColor}
          sidebar={light2Color.sidebarBackgroundColor}
          sidebarText={light2Color.sidebarTextColor}
          sidebarHeader={light2Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Light2)}
          name={'Magritte'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Background1}
          primary={background1Color.primaryColor}
          secondary={background1Color.secondaryColor}
          contract={background1Color.contrastColor}
          background={background1Color.backgroundColor}
          text={background1Color.textColor}
          header={background1Color.headerColor}
          sidebar={background1Color.sidebarBackgroundColor}
          sidebarText={background1Color.sidebarTextColor}
          sidebarHeader={background1Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Background1)}
          name={'Munch'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Background2}
          primary={background2Color.primaryColor}
          secondary={background2Color.secondaryColor}
          contract={background2Color.contrastColor}
          background={background2Color.backgroundColor}
          text={background2Color.textColor}
          header={background2Color.headerColor}
          sidebar={background2Color.sidebarBackgroundColor}
          sidebarText={background2Color.sidebarTextColor}
          sidebarHeader={background2Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Background2)}
          name={'Dali'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Dark1}
          primary={dark1Color.primaryColor}
          secondary={dark1Color.secondaryColor}
          contract={dark1Color.contrastColor}
          background={dark1Color.backgroundColor}
          text={dark1Color.textColor}
          header={dark1Color.headerColor}
          sidebar={dark1Color.sidebarBackgroundColor}
          sidebarText={dark1Color.sidebarTextColor}
          sidebarHeader={dark1Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Dark1)}
          name={'Vermeer'}
        />
        <ColorPalette
          selected={colorPalette === DesignColorPaletteEnum.Dark2}
          primary={dark2Color.primaryColor}
          secondary={dark2Color.secondaryColor}
          contract={dark2Color.contrastColor}
          background={dark2Color.backgroundColor}
          text={dark2Color.textColor}
          header={dark2Color.headerColor}
          sidebar={dark2Color.sidebarBackgroundColor}
          sidebarText={dark2Color.sidebarTextColor}
          sidebarHeader={dark2Color.sidebarHeaderColor}
          onClick={() => onActionClick(DesignColorPaletteEnum.Dark2)}
          name={'Degas'}
        />
      </ColorPalettes>
    </Container>
  );
};
