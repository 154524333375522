import { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor, Transforms, createEditor, Descendant, Element, Text, Node as SlateNode, Node, Location } from 'slate';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { withHistory } from 'slate-history';

import { WritingStyle } from '../../../generated/types';
import { useUserContext } from '../../../contexts/UserContext';

import {
  Button,
  ButtonInfo,
  Container,
  ElementTemplate,
  ExtraHighlight,
  ExtraHighlightButtons,
  LeafTemplate,
  Toolbar,
  ToolbarSection,
} from './SlateEditor.styles';
import { BlockFormat, ExtendedReactEditor, MarkFormat } from './SlateEditor.types';
import { SparklingIcon } from '../../atoms/Icons/SparklingIcon';
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  CheckFullIcon,
  CheckIcon,
  EraserIcon,
  ExpandIcon,
  ItalicIcon,
  ListOrdered,
  ListUnordered,
  RedoIcon,
  RemoveIcon,
  ShortenIcon,
  UnderlineIcon,
  UndoIcon,
} from '../../atoms/Icons';
import { TinyLoader } from '../../atoms/Loader';
import { ErrorBoundary } from '../ErrorBoundary';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button';
import { Colors } from '../../../styles/colors';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const withParagraphs = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // If the element is a paragraph, ensure its children are valid.
    if (Element.isElement(node) && ['paragraph'].includes(node.type)) {
      for (const [child, childPath] of SlateNode.children(editor, path)) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};

export const SlateEditor = ({
  onChange,
  onImproveDescription,
  onAddHighlights,
  initialValue = [],
  style,
}: {
  onChange: (value: Descendant[]) => void;
  onFixTypo?: (content: string) => Promise<string | undefined>;
  onImproveDescription?: (content: any, writingStyle: WritingStyle) => Promise<string[]>;
  onAddHighlights?: () => Promise<string[] | undefined>;
  initialValue: Descendant[];
  style?: React.CSSProperties;
}) => {
  const [hightLights, setHightLights] = useState<string[]>([]);
  const [currentHightLights, setCurrentHightLights] = useState<number>(0);
  const renderElement = useCallback((props: any) => <ElementTemplate {...props} />, []);
  const renderLeaf = useCallback((props: any) => <LeafTemplate {...props} />, []);
  const editor = useMemo<ExtendedReactEditor>(
    () => withSuggestions(withLoading(withHistory(withReact(withParagraphs(createEditor()))))),
    []
  );
  const [isAiLoading, setIsAiLoading] = useState<WritingStyle | ''>('');
  const user = useUserContext();
  const decorate = useCallback(([node, path]) => {
    const ranges = [];

    if (Text.isText(node)) {
      const { text } = node;
      const matches = text.match(/\[.*?\]|[^[\]]+/g);
      let offset = 0;
      if (matches) {
        matches.forEach((part) => {
          if (part.match(/\[.*?\]+/g)) {
            ranges.push({
              anchor: { path, offset: offset },
              focus: { path, offset: offset + part.length },
              highlight: true,
            });
          }
          offset = offset + part.length;
        });
      }
    }

    return ranges;
  }, []);
  useEffect(() => {
    async function fetchHighlights() {
      try {
        setIsAiLoading(WritingStyle.AddHighlights);
        const results = await onAddHighlights?.();
        if (results) {
          setHightLights((old) => [...old, ...results]);
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsAiLoading('');
      }
    }
    if (!Boolean(onAddHighlights)) {
      return;
    }
    const hasHighlights = hightLights.length - currentHightLights > 0;
    if (hasHighlights) {
      return;
    }
    console.log('Loading highlights', currentHightLights);

    fetchHighlights();
  }, [currentHightLights]);

  const isMember = user?.isMember;
  const hasHighlights = hightLights.length - currentHightLights > 0;

  return (
    <ErrorBoundary message="Molecule\SlateEditor">
      <Container>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={async (value) => {
            const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
            if (isAstChange) {
              await onChange(value);
            }
          }}
        >
          <Toolbar>
            <ToolbarSection>
              <MarkButton format="bold" icon={<BoldIcon />} />
              <MarkButton format="italic" icon={<ItalicIcon />} />
              <MarkButton format="underline" icon={<UnderlineIcon />} />
              <BlockButton format="numbered-list" icon={<ListOrdered />} />
              <BlockButton format="bulleted-list" icon={<ListUnordered />} />
              <BlockButton format="left" icon={<AlignLeftIcon />} />
              <BlockButton format="center" icon={<AlignCenterIcon />} />
              <BlockButton format="right" icon={<AlignRightIcon />} />
              <BlockButton format="justify" icon={<AlignJustifyIcon />} />
            </ToolbarSection>
            <ToolbarSection>
              <Button
                onMouseDown={(event) => {
                  event.preventDefault();
                  if (isAiLoading) {
                    return;
                  }
                  editor.undo();
                  if (hasLoading(editor) || hasSuggestions(editor)) {
                    editor.undo();
                  }
                  if (hasLoading(editor) || hasSuggestions(editor)) {
                    editor.undo();
                  }
                }}
              >
                <UndoIcon size={1.2} />
                <ButtonInfo>Undo</ButtonInfo>
              </Button>
              <Button
                onMouseDown={(event) => {
                  event.preventDefault();
                  if (isAiLoading) {
                    return;
                  }
                  editor.redo();
                  if (hasLoading(editor) || hasSuggestions(editor)) {
                    editor.redo();
                  }
                  if (hasLoading(editor) || hasSuggestions(editor)) {
                    editor.redo();
                  }
                }}
              >
                <RedoIcon size={1.2} />
                <ButtonInfo>Redo</ButtonInfo>
              </Button>
              <Button
                onMouseDown={(event) => {
                  event.preventDefault();
                  if (isAiLoading || hasSuggestions(editor)) {
                    return;
                  }
                  clearAll(editor);
                }}
              >
                <EraserIcon size={1.2} />
                <ButtonInfo>Clear All</ButtonInfo>
              </Button>
              {isMember && (
                <>
                  <Button
                    onMouseDown={async (event: any) => {
                      event.preventDefault();

                      if (isAiLoading || hasSuggestions(editor)) {
                        return;
                      }

                      const { nodes, start, end } = getCommonBlocks(editor);

                      try {
                        setIsAiLoading(WritingStyle.Spellcheck);
                        if (nodes && nodes.length > 0) {
                          insertLoading(editor, nodes, start, end);
                          const results = (await onImproveDescription?.(
                            nodes,
                            WritingStyle.Spellcheck
                          )) as unknown as Array<Descendant[]>;
                          if (results && results.length > 0) {
                            insertContent(editor, results[0], start, end);
                          } else {
                            throw new Error('No results from backend');
                          }
                        }
                      } catch (error) {
                        console.error('error', error);
                        const reversedNode = [...nodes].reverse();
                        for (const node of reversedNode) {
                          Transforms.insertNodes(editor, node, { at: [start] });
                        }
                        Transforms.removeNodes(editor, {
                          at: [start + nodes.length],
                        });
                      } finally {
                        setIsAiLoading('');
                      }
                    }}
                  >
                    {isAiLoading === WritingStyle.Spellcheck ? <TinyLoader /> : <CheckIcon size={1.2} />}
                    <ButtonInfo>SpellFix</ButtonInfo>
                  </Button>

                  <Button
                    onMouseDown={async (event: any) => {
                      event.preventDefault();
                      if (isAiLoading || hasSuggestions(editor)) {
                        return;
                      }

                      const { nodes, start, end } = getCommonBlocks(editor);
                      try {
                        setIsAiLoading(WritingStyle.Rewrite);
                        if (nodes && nodes.length > 0) {
                          insertLoading(editor, nodes, start, end);
                          const results = await onImproveDescription?.(nodes, WritingStyle.Rewrite);
                          if (results && results.length > 0) {
                            insertSuggestion(editor, nodes, results, start, end);
                          } else {
                            throw new Error('No results from backend');
                          }
                        }
                      } catch (error) {
                        console.error('error', error);
                        const reversedNode = [...nodes].reverse();
                        for (const node of reversedNode) {
                          Transforms.insertNodes(editor, node, { at: [start] });
                        }
                        Transforms.removeNodes(editor, {
                          at: [start + nodes.length],
                        });
                      } finally {
                        setIsAiLoading('');
                      }
                    }}
                  >
                    {isAiLoading === WritingStyle.Rewrite ? <TinyLoader /> : <SparklingIcon size={1.2} />}
                    <ButtonInfo>Rewrite</ButtonInfo>
                  </Button>

                  <Button
                    onMouseDown={async (event: any) => {
                      event.preventDefault();
                      if (isAiLoading || hasSuggestions(editor)) {
                        return;
                      }

                      const { nodes, start, end } = getCommonBlocks(editor);
                      try {
                        setIsAiLoading(WritingStyle.Expand);
                        if (nodes && nodes.length > 0) {
                          insertLoading(editor, nodes, start, end);
                          const results = await onImproveDescription?.(nodes, WritingStyle.Expand);
                          if (results && results.length > 0) {
                            insertSuggestion(editor, nodes, results, start, end);
                          } else {
                            throw new Error('No results from backend');
                          }
                        }
                      } catch (error) {
                        console.error('error', error);
                        const reversedNode = [...nodes].reverse();
                        for (const node of reversedNode) {
                          Transforms.insertNodes(editor, node, { at: [start] });
                        }
                        Transforms.removeNodes(editor, {
                          at: [start + nodes.length],
                        });
                      } finally {
                        setIsAiLoading('');
                      }
                    }}
                  >
                    {isAiLoading === WritingStyle.Expand ? <TinyLoader /> : <ExpandIcon size={1.2} />}
                    <ButtonInfo>Expand</ButtonInfo>
                  </Button>

                  <Button
                    onMouseDown={async (event: any) => {
                      event.preventDefault();
                      if (isAiLoading || hasSuggestions(editor)) {
                        return;
                      }

                      const { nodes, start, end } = getCommonBlocks(editor);
                      try {
                        setIsAiLoading(WritingStyle.Shorten);
                        if (nodes && nodes.length > 0) {
                          insertLoading(editor, nodes, start, end);
                          const results = await onImproveDescription?.(nodes, WritingStyle.Shorten);
                          if (results && results.length > 0) {
                            insertSuggestion(editor, nodes, results, start, end);
                          } else {
                            throw new Error('No results from backend');
                          }
                        }
                      } catch (error) {
                        console.error('error', error);
                        const reversedNode = [...nodes].reverse();
                        for (const node of reversedNode) {
                          Transforms.insertNodes(editor, node, { at: [start] });
                        }
                        Transforms.removeNodes(editor, {
                          at: [start + nodes.length],
                        });
                      } finally {
                        setIsAiLoading('');
                      }
                    }}
                  >
                    {isAiLoading === WritingStyle.Shorten ? <TinyLoader /> : <ShortenIcon size={1.2} />}
                    <ButtonInfo>Shorten</ButtonInfo>
                  </Button>

                  {/* {Boolean(onAddHighlights) ? (
                    <Button
                      onMouseDown={async (event: any) => {
                        event.preventDefault();
                        if (isAiLoading || hasSuggestions(editor)) {
                          return;
                        }

                        try {
                          setIsAiLoading(WritingStyle.AddHighlights);
                          const results = await onAddHighlights?.();
                          console.log('results', results);
                          if (results && results.length > 0) {
                            setHightLights((old) => [...old, ...results]);
                            // const node = {
                            //   type: 'bulleted-list',
                            //   children: results.map((result) => {
                            //     return {
                            //       type: 'list-item',
                            //       children: [{ text: result }],
                            //     };
                            //   }),
                            // };
                            // console.log('node', node);
                            // Transforms.insertNodes(editor, node, { at: [editor.children.length] });
                          } else {
                            throw new Error('No results from backend');
                          }
                        } catch (error) {
                          console.error('error', error);
                        } finally {
                          setIsAiLoading('');
                        }
                      }}
                    >
                      {isAiLoading === WritingStyle.AddHighlight ? <TinyLoader /> : <AddIcon size={1.2} />}
                      <ButtonInfo>Highlight</ButtonInfo>
                    </Button>
                  ) : null} */}

                  {/* <Button
                    onMouseDown={async (event: any) => {
                      event.preventDefault();
                      try {
                        let hasHelp = false;
                        editor?.children.forEach((node) => {
                          if (node?.type === 'help') {
                            const path = ReactEditor.findPath(editor, node);
                            Transforms.removeNodes(editor, { at: path });
                            hasHelp = true;
                          }
                        });
                        if (!hasHelp) {
                          insertHelp(editor);
                        }
                      } finally {
                        setIsAiLoading('');
                      }
                    }}
                  >
                    <HelpIcon>Help</HelpIcon>
                  </Button> */}
                </>
              )}
            </ToolbarSection>
          </Toolbar>
          <Editable
            style={style}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            autoFocus
            onKeyDown={(event) => {
              if (!event.ctrlKey) {
                return;
              }
              switch (event.key) {
                case 'b': {
                  event.preventDefault();
                  toggleMark(editor, 'bold');
                  break;
                }
                case 'i': {
                  event.preventDefault();
                  toggleMark(editor, 'italic');
                  break;
                }
                case 'u': {
                  event.preventDefault();
                  toggleMark(editor, 'underline');
                  break;
                }
              }
            }}
            decorate={decorate}
            // placeholder="Type something"
            // renderPlaceholder={({ children, attributes }) => (
            //   <div {...attributes}>
            //     <p>{children}</p>
            //     <pre>Use the renderPlaceholder prop to customize rendering of the placeholder</pre>
            //   </div>
            // )}
          />
        </Slate>
      </Container>

      {Boolean(onAddHighlights) && (
        <>
          {hasHighlights ? (
            <ExtraHighlight>
              <div>
                <ul>
                  <li>{hightLights[currentHightLights]}</li>
                </ul>
              </div>
              <ExtraHighlightButtons>
                <SecondaryButton
                  inline={true}
                  size="tiny"
                  onClick={() => {
                    setCurrentHightLights((old) => old + 1);
                  }}
                >
                  Skip
                </SecondaryButton>
                <PrimaryButton
                  inline={true}
                  size="tiny"
                  onClick={() => {
                    const node = {
                      type: 'bulleted-list',
                      children: [
                        {
                          type: 'list-item',
                          children: [{ text: hightLights[currentHightLights] }],
                        },
                      ],
                    };
                    Transforms.insertNodes(editor, node, { at: [editor.children.length] });
                    setCurrentHightLights((old) => old + 1);
                  }}
                  iconRight={<CheckFullIcon size={1} color={Colors.White} />}
                >
                  Use
                </PrimaryButton>
              </ExtraHighlightButtons>
            </ExtraHighlight>
          ) : (
            <>
              {isAiLoading === WritingStyle.AddHighlights ? (
                <ExtraHighlight isLoading={true}>
                  🤖 Our AI is working hard to provide you with more suggestions...
                </ExtraHighlight>
              ) : (
                <ExtraHighlight isLoading={false}>
                  <ExtraHighlightButtons>
                    <PrimaryButton
                      inline={true}
                      size="tiny"
                      onClick={async (event) => {
                        event.preventDefault();
                        if (isAiLoading || hasSuggestions(editor)) {
                          return;
                        }
                        try {
                          setIsAiLoading(WritingStyle.AddHighlights);
                          const results = await onAddHighlights?.();
                          if (results && results.length > 0) {
                            setHightLights((old) => [...old, ...results]);
                          } else {
                            throw new Error('No results from backend');
                          }
                        } catch (error) {
                          console.error('error', error);
                        } finally {
                          setIsAiLoading('');
                        }
                      }}
                      iconRight={<SparklingIcon size={1} color={Colors.White} />}
                    >
                      Get more AI suggestions
                    </PrimaryButton>
                  </ExtraHighlightButtons>
                </ExtraHighlight>
              )}
            </>
          )}
        </>
      )}
      {/* <div
        style={{
          width: '400px',
        }}
      >
        <pre>{JSON.stringify(initialValue, null, 2)}</pre>
      </div> */}
    </ErrorBoundary>
  );
};

const insertHelp = (editor: ExtendedReactEditor) => {
  const helpNode = {
    type: 'help',
    children: [
      {
        text: '',
      },
    ],
  };
  try {
    Transforms.insertNodes(editor, helpNode);
  } catch (error) {
    console.error('error', error);
  }
};

const insertSuggestion = (
  editor: ExtendedReactEditor,
  original: Descendant[],
  suggestions: Descendant[],
  start: number,
  end: number
) => {
  const suggestionsNode = {
    type: 'suggestion',
    original,
    suggestions,
    children: [
      {
        text: 'Select your suggestion...',
      },
    ],
    start,
    end,
  };
  try {
    Transforms.insertNodes(editor, suggestionsNode, { at: [start] });
    Transforms.removeNodes(editor, { at: [start + 1] });
  } catch (error) {
    console.error('error', error);
  }
};

const insertLoading = (editor: ExtendedReactEditor, nodes: Descendant[], start: number, end: number) => {
  const loadingNode = {
    type: 'loading',
    original: nodes,
    children: [
      {
        text: 'Loading...',
      },
    ],
    start,
    end,
  };

  try {
    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, loadingNode, { at: [start] });
      for (let i = start + 1; i < end + 2; i++) {
        Transforms.removeNodes(editor, { at: [start + 1] });
      }
    });
  } catch (error) {
    console.error('error', error);
  }
};

const insertContent = (editor: ExtendedReactEditor, nodes: Descendant[], start: number, end: number) => {
  try {
    const nodesCount = editor?.children.length;
    const updatedNodes = [...editor?.children];
    updatedNodes.splice(start, 1, ...nodes);

    Editor.withoutNormalizing(editor, () => {
      // Insert the new nodes at the end of the document. This is important to
      // duplicate key in react element.
      Transforms.insertNodes(editor, updatedNodes, { at: [editor.children.length] });
      // Remove all old nodes from the start to the end of the document.
      for (let i = 0; i < nodesCount; i++) {
        Transforms.removeNodes(editor, { at: [0] });
      }
    });
  } catch (error) {
    console.error('error', error);
  }
};

const clearAll = (editor: ExtendedReactEditor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
};

const withSuggestions = (editor: ExtendedReactEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'suggestion' ? true : isVoid(element);
  };

  return editor;
};

const withLoading = (editor: ExtendedReactEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'loading' ? true : isVoid(element);
  };

  return editor;
};

const toggleBlock = (editor: ExtendedReactEditor, format: BlockFormat) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<Element>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: ExtendedReactEditor, format: MarkFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: ExtendedReactEditor, format: BlockFormat, blockType = 'type'): boolean => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: ExtendedReactEditor, format: MarkFormat) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const BlockButton = ({ format, icon }: { format: BlockFormat; icon: React.ReactNode }) => {
  const editor = useSlate();
  return (
    <Button
      $active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

const MarkButton = ({ format, icon }: { format: MarkFormat; icon: React.ReactNode }) => {
  const editor = useSlate();
  return (
    <Button
      $active={isMarkActive(editor, format)}
      onMouseDown={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

function getCommonBlocks(editor: ExtendedReactEditor): {
  start: number;
  end: number;
  // blocks: string[];
  nodes: any[];
} {
  try {
    const nodes = [];

    const range = Editor.unhangRange(editor, editor.selection, { voids: true });
    const start = range.anchor.path[0] < range.focus.path[0] ? range.anchor.path[0] : range.focus.path[0];
    const end = range.anchor.path[0] > range.focus.path[0] ? range.anchor.path[0] : range.focus.path[0];

    for (let i = start; i <= end; i++) {
      let [common] = SlateNode.common(editor, [i, 0], [i, 100]);
      nodes.push(common);
    }

    return {
      start,
      end,
      nodes,
    };
  } catch (error) {
    return {
      start: 0,
      end: 0,
      nodes: [],
    };
  }
}

function hasSuggestions(editor): boolean {
  let hasSuggestion = false;
  editor?.children.forEach((node) => {
    if (node?.type === 'suggestion') {
      hasSuggestion = true;
    }
  });
  return hasSuggestion;
}

function hasLoading(editor): boolean {
  let hasSuggestion = false;
  editor?.children.forEach((node) => {
    if (node?.type === 'loading') {
      hasSuggestion = true;
    }
  });
  return hasSuggestion;
}

const flattenParagraphs = (nodes): any => {
  // console.log('flattenParagraphs', nodes);
  // if empty array
  if (nodes.length === 0) {
    return [{ type: 'paragraph', children: [{ text: '' }] }];
  }
  if (nodes.length === 1 && nodes[0].type === 'paragraph') {
    return flattenParagraphs(nodes[0].children);
  } else {
    return nodes;
  }
};
