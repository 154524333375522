import { Icon, IconProps } from './Icon';
import { Expand } from '@styled-icons/material-sharp';

export function ExpandIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Expand />
    </Icon>
  );
}
