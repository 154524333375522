import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  position: relative;
`;

export const DesignPalettes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Primary};
  padding: 8px;
`;

export const DesignPaletteButton = styled.button<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  border: 1px solid ${Colors.Contrast};
  ${({ $selected }) =>
    $selected &&
    `
    border: 4px solid ${Colors.PrimaryDarkest};
    `}

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

export const SelectionIndicator = styled.div<{ $selected: boolean }>`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: ${Colors.White};
  border-top: 1px solid ${Colors.Primary};
  border-left: 1px solid ${Colors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  bottom: 0;
  right: 0;
  border-radius: 0 0 8px 0;
  svg {
    display: none;
  }

  ${({ $selected }) =>
    $selected &&
    `
    svg {
      display: block;
    }
  `}
`;

export const SelectionLabel = styled.span`
  display: flex;
`;

export const ImageContainer = styled.div<{ $loading: boolean }>`
  height: ${({ $loading }) => ($loading ? '330px' : 'fit-content')};
  filter: blur(${({ $loading }) => ($loading ? '5px' : '0')});
`;

export const LeftIconContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;

export const RightIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;
